const sort = (data, currentSortKey, sortOrder) => {
    data.sort((a, b) => {
        let valueA = a[currentSortKey];
        let valueB = b[currentSortKey];
        
        // Handle nested properties
        if (currentSortKey.includes('.')) {
            const [parent, child] = currentSortKey.split('.');
            valueA = a[parent][child];
            valueB = b[parent][child];
        }
        
        if (sortOrder === 'asc') {
            return valueA > valueB ? 1 : -1;
        }
        return valueA < valueB ? 1 : -1;
    });
    
    return data;
}

const search = (data, term) => {
    data = data.filter(item => {
        return Object.entries(term).every(([key, value]) => {
            if (!value) return true;
            // Handle nested properties
            if (key.includes('.')) {
                const [parent, child] = key.split('.');
                return item[parent][child]?.toLowerCase().includes(value.toLowerCase());
            }
            
            if (typeof item[key] === 'boolean') {
                return value === item[key].toString();
            }
            return item[key]?.toLowerCase().includes(value.toLowerCase());
        });
    });
    
    return data;
}

const universalSearch = (data, searchTerm) => {
    
    if (!searchTerm) return data;
    searchTerm = searchTerm.toLowerCase();
    return data.filter(item => {
        return Object.values(item).some(value => {
            if (typeof value === 'object' && value !== null) {
                return Object.values(value).some(nestedValue =>
                    nestedValue?.toString().toLowerCase().includes(searchTerm)
                );
            }
            return value?.toString().toLowerCase().includes(searchTerm);
        });
    });
}


export {sort, search, universalSearch};
