import React, {useContext} from 'react';
import {Breadcrumb} from 'react-bootstrap';
import {Nav, Navbar} from "react-bootstrap";
import {
    Outlet,
    useLocation,
} from "react-router-dom";
import {AuthContext} from '../components/AuthProvider';


export const Home = () => {
    const {user} = useContext(AuthContext);
    document.title = 'AUGB Leeds Library';
    let location = useLocation();
    
    const getClass = (menuItem) => {
        if (location.pathname.includes(menuItem)) {
            return 'text-light';
        }
        return '';
    }
    
    return (
        <>
            <Navbar expand="lg" bg="dark" data-bs-theme="dark">
                <div className="container-fluid">
                    <Navbar.Brand href="#home">
                        Бібліотека при CУБ м.Лідс</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/">Домашня Сторінка</Nav.Link>
                            <Nav.Link href="/books" className={getClass('books')}>Книги</Nav.Link>
                            {user ? <>
                                <Nav.Link href="/readers" className={getClass('readers')}>Читачі</Nav.Link>
                                <Nav.Link href="/loans" className={getClass('loans')}>Видані Книги</Nav.Link>
                            </> : null}
                            <Nav.Link className={getClass('login')} href="/login">Увійти</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
            
            {location.pathname === "/"
                ? <div className="bg-light">
                    <div className="row">
                        <div className="col ps-4">
                            <Breadcrumb>
                                <Breadcrumb.Item href="/">Домашня Сторінка</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col text-center p-0  ">
                            <div className="row">
                                <div className="col">
                                    <img src="/background.jpg" alt="Logo" className="img-fluid w-100"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <div id="sponsor-logo" className="text-center mt-3">За підтримки:</div>
                                    <img src="/reading-studios.png" alt="Donor" className="img-fluid"/>
                                </div>
                                <div className="front-page-text-container col-8 mt-5 pb-3"
                                     style={{background: '#fff0b6'}}>
                                    <h1 className="mt-3">Про бібліотеку</h1>
                                    <hr/>
                                    <p className="front-page-text">
                                        У грудні 2024 року при Спілці Українців у Британії в місті Лідс відкриється
                                        бібліотека та читальна студія, яка працюватиме у приміщенні клубу організації.
                                    </p>
                                    <p className="front-page-text">
                                        Бібліотечний фонд налічує близько 700 сучасних видань українською мовою для
                                        дорослих і дітей. У нашій колекції представлені книги від провідних видавництв,
                                        зокрема: А-БА-БА-ГА-ЛА-МА-ГА, Віват, Видавництво Старого Лева, Ранок, Фоліо та
                                        багатьох інших.
                                    </p>
                                    <p className="front-page-text">
                                        Цей проєкт став можливим завдяки щедрій підтримці благодійної
                                        організації Reading Studios Foundation. Від імені всієї української громади
                                        Лідса
                                        висловлюємо їм найщирішу вдячність!
                                    </p>
                                    <p className="front-page-text">
                                        Окрему подяку організація висловлює волонтерам, чия відданість і праця зробили
                                        цю бібліотеку реальністю. Ми віримо, що бібліотека стане місцем, яке об’єднує
                                        громаду, дарує радість читання та підтримує українську культуру й мову за межами
                                        Батьківщини.
                                    </p>
                                    <p className="front-page-text">
                                        Запрошуємо завітати до нас і разом створювати спільноту любителів української
                                        книги!
                                    </p>
                                    <hr/>
                                    <a href={"/books"} className="btn btn-outline-primary">Переглянути Книги</a>
                                
                                </div>
                            </div>
                            <div className="row" style={{background: '#6384c4'}}>
                                <div className="col-4">
                                    <img src="/boy.png" alt="Welcome" className="w-100"/>
                                </div>
                                <div className="col-8">
                                    <h2 className="mt-4">Правила користування бібліотекою</h2>
                                    <hr/>
                                    <p className="front-page-text">
                                        <ul>
                                            <li><strong>Реєстрація: </strong>
                                                Для отримання книжки необхідно зареєструватися. Зверніться до
                                                бібліотекаря-волонтера або до пані Олі на барі.
                                            </li>
                                            <li>
                                                <strong>Користування книгами: </strong>
                                                <ul>
                                                    <li>
                                                        Книги можна брати у користування на період 2-3 тижнів.
                                                    </li>
                                                    <li>
                                                        Якщо ви хочете продовжити термін користування, повідомте
                                                        бібліотекаря
                                                        заздалегідь.
                                                    </li>
                                                    <li>
                                                        Книгами також можна користуватися на місці в читальній залі.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Повернення книг: </strong>
                                                Повернення книг відбувається на барі в приміщенні клубу.
                                            </li>
                                            <li>
                                                <strong>Дбайливе ставлення до книг: </strong>
                                                <ul>
                                                    <li>
                                                        Просимо дбайливо ставитися до книг, уникати пошкоджень,
                                                        малювання чи забруднень.
                                                    </li>
                                                    <li>
                                                        У разі втрати чи значного пошкодження книги необхідно
                                                        відшкодувати її вартість або надати заміну.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <strong>Штрафи та відповідальність: </strong>
                                                За затримку повернення книги понад визначений термін можуть
                                                застосовуватися штрафи (деталі уточнюйте у бібліотекаря).
                                            </li>
                                        </ul>
                                    </p>
                                    <hr/>
                                    <h5 className=" text-center">
                                        Якщо у вас є питання або особливі прохання, звертайтеся до бібліотекаря – ми
                                        завжди раді допомогти!
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="front-page-text-container col mt-3 pb-3"
                                     style={{background: '#fff0b6'}}>
                                    <div className="row">
                                        <div className="col-4 pt-5">
                                            <h1 className="mt-3 fw-bold" id="contact-us">
                                                КОНТАКТИ:
                                            </h1>
                                        </div>
                                        <div className="col-8 p-5">
                                            <div className="alert alert-primary me-5">
                                                
                                                <p>The Association of Ukrainians in Great Britain</p>
                                                <p>5 Back Newton Grove, Leeds LS7 4HW</p>
                                                <p>01132627063</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
                : <Outlet/>}
        
        </>
    
    
    );
}

export default Home;
