import React, {useState, useEffect, useContext} from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getData, getAllCollectionRecords, submitData} from '../components/addUpdateRecord';
import {AuthContext} from '../components/AuthProvider';


const BorrowBook = ({loanId, bookId, readerId}) => {
    const {user} = useContext(AuthContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [readers, setReaders] = useState([]);
    const [books, setBooks] = useState([]);
    const [formData, setFormData] = useState({
        book: null,
        reader: null,
        dateBorrowed: '',
        dateReturned: '',
        borrowedBy: '',
    });
    
    
    const handleChange = (key, value) => {
        setFormData({
            ...formData,
            [key]: value,
            borrowedBy: user.displayName ?? ''
        });
    }
    
    
    const handleSubmit = async () => {
        setIsSubmitting(true)
        await submitData(
            {isAvailable: formData.dateReturned.length > 0},
            'books',
            formData.book.id);
        
        await submitData(formData, 'loans', formData.id).then(() => {
            setIsDialogOpen(false);
            document.location.reload();
        });
        
    }
    const handleDialogueClose = () => {
        setIsDialogOpen(false);
    };
    const getReaders = async () => {
        try {
            getAllCollectionRecords('readers').then((result) => {
                setReaders(result);
            })
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    const getBooks = async () => {
        try {
            getAllCollectionRecords('books').then((result) => {
                const onlyAvailableBooks = result.filter((book) => book.isAvailable);
                setBooks(onlyAvailableBooks);
            })
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    
    const handleShow = () => {
        if (!readerId && bookId) {
            getReaders();
            getData(bookId, 'books').then((res) => {
                handleChange('book', res);
            });
            
        } else if (!bookId && readerId) {
            getBooks();
            getData(readerId, 'readers').then((res) => {
                handleChange('reader', res);
            });
        } else {
            getReaders();
            getBooks();
        }
        
        if (loanId) {
            getData(loanId, 'loans').then((res) => {
                setFormData(res);
            });
        }
        
        setIsDialogOpen(true);
    }
    
    return (
        <>
            <span
                className={!loanId ? 'btn btn-outline-primary' : 'btn-link text-warning ms-2'}
                onClick={() => handleShow()}
                title="Натисніть щоб Редагавати видачу"
            >
                {!loanId ? 'Видати Книгу' : <FontAwesomeIcon icon={'edit'}/>}
            </span>
            
            <Modal show={isDialogOpen} onHide={handleDialogueClose}>
                <Modal.Header closeButton autoFocus className="bg-light">
                    <Modal.Title>Читач Книги</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!isLoading &&
                        <>
                            {!readerId && <div className="row mt-2">
                                <div className="col form-group">
                                    <label
                                        htmlFor="reader"
                                        className={'form-label label-required'}
                                    >Читач</label>
                                    <Select
                                        id="scan-policy"
                                        isClearable
                                        required
                                        options={readers}
                                        onChange={(value) => handleChange('reader', value)}
                                        placeholder={'Select Reader'}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        value={formData.reader}
                                    />
                                </div>
                            </div>}
                            {!bookId && <div className="row mt-2">
                                <div className="col form-group">
                                    <label
                                        htmlFor="book"
                                        className={'form-label label-required'}
                                    >Книга</label>
                                    <Select
                                        id="scan-policy"
                                        isClearable
                                        required
                                        options={books}
                                        onChange={(value) => handleChange('book', value)}
                                        placeholder={'Select Reader'}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.title}
                                        value={formData.book}
                                    />
                                </div>
                            </div>}
                            <div className="row mt-2">
                                <div className="col form-group">
                                    <label htmlFor="start-date" className="form-label label-required">
                                        Видана
                                    </label>
                                    <input
                                        id="start-date"
                                        className="form-control"
                                        type="date"
                                        value={formData.dateBorrowed}
                                        onChange={(e) => handleChange('dateBorrowed', e.target.value)}
                                    />
                                </div>
                                
                                <div className="col">
                                    <label htmlFor="end-date" className="form-label">
                                        Повернута
                                    </label>
                                    <input
                                        id="end-date"
                                        className="form-control"
                                        type="date"
                                        value={formData.dateReturned}
                                        onChange={(e) => handleChange('dateReturned', e.target.value)}
                                    />
                                </div>
                            </div>
                        
                        </>
                        
                    }
                </Modal.Body>
                <Modal.Footer className="bg-light">
                    <button className="btn btn-sm btn-outline-primary ms-2"
                            onClick={() => handleSubmit()}
                    >   {isSubmitting && <span><i className="fas fa-spinner fa-spin me-2"/>Відправка даних...</span>}
                        {!isSubmitting && <strong>Зберегти</strong>}
                    </button>
                    <button className="btn btn-sm btn-outline-dark ms-2"
                            onClick={() => handleDialogueClose()}
                    >Відміна
                    </button>
                </Modal.Footer>
            </Modal>
        
        </>
    )
}

export default BorrowBook;
