import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Breadcrumb} from 'react-bootstrap';
import {deleteDocumentById, getAllCollectionRecords} from '../components/addUpdateRecord';
import {search, sort} from '../components/SearchAndSort';
import SearchInput from '../components/SearchInput';
import SortHeader from '../components/SortHeader';
import Paginator from '../components/Paginator';


export const Readers = () => {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [currentSortKey, setCurrentSortKey] = useState('name');
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [searchObj, setSearchObj] = useState({
        name: '',
        email: '',
        phoneNumber: ''
    });
    
    // Filter and sort data
    const processedData = useMemo(() => {
        let filteredData = [...tableData];
        filteredData = search(filteredData, searchObj);
        filteredData = sort(filteredData, currentSortKey, sortOrder);
        return filteredData;
    }, [tableData, searchObj, sortOrder, currentSortKey]);
    
    // Calculate pagination values
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = processedData.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(tableData.length / itemsPerPage);
    
    // Handle search
    const handleSearch = useCallback((value, fieldName) => {
        
        setSearchObj((prev) => ({...prev, [fieldName]: value}));
        setCurrentPage(1); // Reset to the first page on search
    }, []);
    
    const loadData = async () => {
        if (isLoading) return;
        setIsLoading(true);
        
        try {
            const data = await getAllCollectionRecords('readers');
            setTableData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        loadData().then(r => {
            setIsLoading(false);
        });
    }, []);
    
    
    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this Book?")) {
            try {
                await deleteDocumentById('readers', id);
                document.location.href = '/readers';
            } catch (error) {
                console.error("Error deleting document:", error);
            }
        }
    }
    
    const handleSortClick = (sortKey) => {
        if (currentSortKey === sortKey) {
            setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
        } else {
            setCurrentSortKey(sortKey);
            setSortOrder('desc');
        }
    };
    
    
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Домашня Сторінка</Breadcrumb.Item>
                        <Breadcrumb.Item active>Читачі</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <a className="btn btn-outline-primary" href="/readers/edit-reader">Додати Читача</a>
                </div>
            
            </div>
            <div className="row">
                <div className="col">
                    {!isLoading && <>
                        <table className="table table-striped border shadow">
                            <thead>
                            
                            <tr>
                                <SortHeader
                                    label="Ім'я"
                                    sortKey="name"
                                    sortOrder={sortOrder}
                                    currentSortKey={currentSortKey}
                                    handleSortClick={handleSortClick}
                                />
                                <SortHeader
                                    label="Електронна Адреса"
                                    sortKey="email"
                                    sortOrder={sortOrder}
                                    currentSortKey={currentSortKey}
                                    handleSortClick={handleSortClick}
                                />
                                <SortHeader
                                    label="Номер Телефону"
                                    sortKey="phoneNumber"
                                    sortOrder={sortOrder}
                                    currentSortKey={currentSortKey}
                                    handleSortClick={handleSortClick}
                                />
                                <SortHeader
                                    label=" Альтернативний Номер Телефону"
                                    sortKey="phoneNumberAlt"
                                    sortOrder={sortOrder}
                                    currentSortKey={currentSortKey}
                                    handleSortClick={handleSortClick}
                                />
                                <th>Дії</th>
                            </tr>
                            <tr>
                                
                                <SearchInput
                                    fieldName="name"
                                    value={searchObj.name}
                                    onSearchChange={handleSearch}
                                />
                                
                                <SearchInput
                                    fieldName="email"
                                    value={searchObj.email}
                                    onSearchChange={handleSearch}
                                />
                                
                                <SearchInput
                                    fieldName="phoneNumber"
                                    value={searchObj.phoneNumber}
                                    onSearchChange={handleSearch}
                                />
                                <SearchInput
                                    fieldName="phoneNumberAlt"
                                    value={searchObj.phoneNumberAlt}
                                    onSearchChange={handleSearch}
                                />
                            
                            </tr>
                            </thead>
                            <tbody>
                            {currentItems.map(item => (
                                <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td>{item.phoneNumber}</td>
                                    <td>{item.phoneNumberAlt}</td>
                                    <td>
                                        <span
                                            className="btn-link text-danger"
                                            title="Delete Reader"
                                            onClick={() => handleDelete(item.id)}
                                        >
                                            <FontAwesomeIcon icon="fa-trash"/>
                                        </span>
                                        <a
                                            className="btn-link text-warning ms-2"
                                            title="Edit reader"
                                            href={`/readers/edit-reader/${item.id}`}
                                        >
                                            <FontAwesomeIcon icon="fa-edit"/>
                                        </a>
                                        <a
                                            className="btn-link text-primary ms-2"
                                            title="Reader's Books"
                                            href={`/readers/reader-books/${item.id}`}
                                        >
                                            <FontAwesomeIcon icon="fa-list"/>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </>}
                </div>
            </div>
            <Paginator
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
            />
        </div>
    );
}

export default Readers;
