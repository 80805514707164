import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Breadcrumb} from 'react-bootstrap';
import {deleteDocumentById, getAllCollectionRecords, getData} from '../components/addUpdateRecord';
import {AuthContext} from '../components/AuthProvider';
import {search, universalSearch, sort} from '../components/SearchAndSort';
import Paginator from '../components/Paginator';
import BooksTilesView from './BooksTilesView';
import BooksGridView from './BooksGridView';
import {getDownloadURL, ref, uploadBytes, deleteObject} from 'firebase/storage';
import {storage} from '../firebase';
import CryptoJS from 'crypto-js';
import RecordsInfo from '../components/RecordsInfo';


export const Books = () => {
    const {user} = useContext(AuthContext);
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState('asc');
    const [currentSortKey, setCurrentSortKey] = useState('title');
    const itemsPerPage = 12;
    const [currentPage, setCurrentPage] = useState(1);
    const defaultSearch = {
        title: '',
        author: '',
        genre: '',
        isAvailable: '',
        auditory: ''
    };
    const [searchObj, setSearchObj] = useState(defaultSearch);
    const [searchValue, setSearchValue] = useState('');
    
    const [tilesView, setTilesView] = useState(
        () => JSON.parse(localStorage.getItem('tilesView')) ?? false
    );
    
    // Filter and sort data
    const processedData = useMemo(() => {
        let filteredData = [...tableData];
        filteredData = search(filteredData, searchObj);
        filteredData = sort(filteredData, currentSortKey, sortOrder);
        filteredData = universalSearch(filteredData, searchValue);
        return filteredData;
    }, [tableData, searchObj, sortOrder, currentSortKey, searchValue]);
    
    // Calculate pagination values
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = processedData.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(tableData.length / itemsPerPage);
    
    
    // Handle search
    const handleSearch = useCallback((value, fieldName) => {
        setSearchObj((prev) => ({...prev, [fieldName]: value}));
        setCurrentPage(1); // Reset to the first page on search
    }, []);
    
    const resetSearch = useCallback(() => {
        setSearchObj(defaultSearch);
        setCurrentPage(1); // Reset to the first page on search
    }, []);
    
    
    const loadData = async () => {
        if (isLoading) return;
        setIsLoading(true);
        
        try {
            const data = await getAllCollectionRecords('books');
            setTableData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        
        loadData().then(r => {
            setIsLoading(false);
        });
    }, []);
    
    
    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this Book?")) {
            try {
                await getData(id, 'books').then((res) => {
                    const imageName = CryptoJS.MD5(res.title).toString();
                    return ref(storage, `images/${imageName}`);
                }).then((imgRef) => {
                    deleteObject(imgRef).catch((error) => {
                        console.warn(`Error deleting previous image for book id ${id}: `, error);
                    });
                    
                });
                await deleteDocumentById('books', id);
                document.location.href = '/books';
                
            } catch (error) {
                console.error("Error deleting document:", error);
            }
        }
    }
    
    const handleSortClick = (sortKey) => {
        if (currentSortKey === sortKey) {
            setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
        } else {
            setCurrentSortKey(sortKey);
            setSortOrder('desc');
        }
    };
    
    useEffect(() => {
        localStorage.setItem('tilesView', JSON.stringify(tilesView));
        
    }, [tilesView]);
    
    
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Домашня Сторінка</Breadcrumb.Item>
                        <Breadcrumb.Item active>Книги</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    {user ? <a className="btn btn-outline-primary" href="/books/edit-book">Додати Книгу</a> : null}
                </div>
                <div className="col">
                    <div className="border border-info rounded p-2 ps-3 float-end">
                        <div className="form-check form-switch d-inline-block">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={tilesView}
                                id="tiles-view"
                                onChange={(e) => setTilesView(!tilesView)}
                            />
                            <label className="form-check-label" htmlFor="is-available">
                                Плитки
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            
            <RecordsInfo
                currentPage={currentPage}
                totalRecords={processedData.length}
                currentItems={currentItems.length}
            
            />
            
            {tilesView ?
                <BooksTilesView
                    sortOrder={sortOrder}
                    currentSortKey={currentSortKey}
                    handleSortClick={handleSortClick}
                    handleSearch={handleSearch}
                    currentItems={currentItems}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                : <BooksGridView
                    user={user}
                    isLoading={isLoading}
                    sortOrder={sortOrder}
                    currentSortKey={currentSortKey}
                    handleSortClick={handleSortClick}
                    handleSearch={handleSearch}
                    currentItems={currentItems}
                    handleDelete={handleDelete}
                    searchObj={searchObj}
                    resetSearch={resetSearch}
                    setTableData={setTableData}
                />
            }
            <Paginator
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
            />
        </div>
    );
}

export default Books;
