import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Breadcrumb} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import BorrowBook from './BorrowBook';
import {deleteDocumentById, getAllCollectionRecords, getData, submitData} from '../components/addUpdateRecord';
import SearchInput from '../components/SearchInput';
import SortHeader from '../components/SortHeader';
import {search, sort} from '../components/SearchAndSort';
import Paginator from '../components/Paginator';

export const BookBorrowers = () => {
    const {bookId, readerId} = useParams();
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortOrder, setSortOrder] = useState('desc');
    const [currentSortKey, setCurrentSortKey] = useState('dateBorrowed');
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [currentBook, setCurrentBook] = useState({});
    const [searchObj, setSearchObj] = useState({
        'book.title': '',
        'reader.name': '',
        dateBorrowed: '',
        dateReturned: '',
        borrowedBy: '',
    });
    
    // Filter and sort data
    const processedData = useMemo(() => {
        let filteredData = [...tableData];
        
        // Filter by bookId or readerId if provided
        if (bookId) {
            filteredData = filteredData.filter(item => item.book.id === bookId);
        } else if (readerId) {
            filteredData = filteredData.filter(item => item.reader.id === readerId);
        }
        
        // Apply search filters
        filteredData = search(filteredData, searchObj);
        
        // Sort data
        filteredData = sort(filteredData, currentSortKey, sortOrder);
        
        return filteredData;
    }, [tableData, searchObj, sortOrder, currentSortKey, bookId, readerId]);
    
    // Calculate pagination values
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = processedData.slice(firstIndex, lastIndex);
    const totalPages = Math.ceil(tableData.length / itemsPerPage);
    
    // Handle search
    const handleSearch = useCallback((value, fieldName) => {
        setSearchObj((prev) => ({...prev, [fieldName]: value}));
        setCurrentPage(1); // Reset to the first page on search
    }, []);
    
    const loadData = async () => {
        if (isLoading) return;
        setIsLoading(true);
        
        try {
            const result = await getAllCollectionRecords('loans');
            const data = result.filter((item) => {
                if (bookId) {
                    if (item.book.id === bookId) {
                        return item;
                    }
                } else if (readerId) {
                    if (item.reader.id === readerId) {
                        return item;
                    }
                }
                return item;
                
            });
            
            setTableData(data);
            
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        loadData().then(r => {
            if (bookId) {
                getData(bookId, 'books').then((res) => {
                    setCurrentBook(res);
                });
            }
            setIsLoading(false);
        });
    }, []);
    
    
    const handleDelete = async (loan) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure you want to delete current record?')) {
            try {
                await deleteDocumentById('loans', loan.id).then(() => {
                    submitData(
                        {isAvailable: true},
                        'books',
                        loan.book.id
                    ).then(() => {
                        document.location.href = '/loans';
                    })
                });
                
            } catch (error) {
                console.error("Error deleting document:", error);
            }
        }
        
    }
    
    const handleSortClick = (sortKey) => {
        if (currentSortKey === sortKey) {
            setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
        } else {
            setCurrentSortKey(sortKey);
            setSortOrder('desc');
        }
    };
    
    
    const BreadCrumbs = () => {
        if (bookId) {
            return (
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Домашня Сторінка</Breadcrumb.Item>
                    <Breadcrumb.Item href="/books">Книги</Breadcrumb.Item>
                    <Breadcrumb.Item active>Читачі {currentBook.title} </Breadcrumb.Item>
                </Breadcrumb>
            );
        } else if (readerId) {
            return (
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Домашня Сторінка</Breadcrumb.Item>
                    <Breadcrumb.Item href="/readers">Читачі</Breadcrumb.Item>
                    <Breadcrumb.Item active>Книги Читача</Breadcrumb.Item>
                </Breadcrumb>
            );
        }
        
        return (
            <Breadcrumb>
                <Breadcrumb.Item href="/">Домашня Сторінка</Breadcrumb.Item>
                <Breadcrumb.Item active>Видані Книги</Breadcrumb.Item>
            </Breadcrumb>
        );
    }
    
    
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <BreadCrumbs/>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    
                    {
                        (currentBook.isAvailable === true || !bookId)
                            ? <BorrowBook newLoan={true} bookId={bookId ?? null} readerId={readerId ?? null}/>
                            : null
                    }
                
                </div>
            
            </div>
            <div className="row">
                <div className="col">
                    <>
                        <table className="table table-striped border shadow">
                            <thead>
                            
                            <tr className="table-light">
                                {!bookId && (
                                    <SortHeader
                                        label="Книга"
                                        sortKey={'book.title'}
                                        sortOrder={sortOrder}
                                        currentSortKey={currentSortKey}
                                        handleSortClick={handleSortClick}
                                    />
                                )}
                                {!readerId && (
                                    <SortHeader
                                        label="Читач"
                                        sortKey={'reader.name'}
                                        sortOrder={sortOrder}
                                        currentSortKey={currentSortKey}
                                        handleSortClick={handleSortClick}
                                    />
                                )}
                                <SortHeader
                                    label="Видана"
                                    sortKey={'dateBorrowed'}
                                    sortOrder={sortOrder}
                                    currentSortKey={currentSortKey}
                                    handleSortClick={handleSortClick}
                                />
                                <SortHeader
                                    label="Повернута"
                                    sortKey={'dateReturned'}
                                    sortOrder={sortOrder}
                                    currentSortKey={currentSortKey}
                                    handleSortClick={handleSortClick}
                                />
                                <SortHeader
                                    label="Ким Видана"
                                    sortKey={'borrowedBy'}
                                    sortOrder={sortOrder}
                                    currentSortKey={currentSortKey}
                                    handleSortClick={handleSortClick}
                                />
                                <th>Дії</th>
                            </tr>
                            <tr>
                                {!bookId && (
                                    <SearchInput
                                        fieldName="book.title"
                                        value={searchObj['book.title']}
                                        onSearchChange={handleSearch}
                                    />
                                )}
                                {!readerId && (
                                    <SearchInput
                                        fieldName="reader.name"
                                        value={searchObj['reader.name']}
                                        onSearchChange={handleSearch}
                                    />
                                )}
                                <SearchInput
                                    fieldName="dateBorrowed"
                                    value={searchObj['dateBorrowed']}
                                    onSearchChange={handleSearch}
                                />
                                <SearchInput
                                    fieldName="dateReturned"
                                    value={searchObj['dateReturned']}
                                    onSearchChange={handleSearch}
                                />
                                <SearchInput
                                    fieldName="borrowedBy"
                                    value={searchObj['borrowedBy']}
                                    onSearchChange={handleSearch}
                                />
                            </tr>
                            </thead>
                            <tbody>
                            {!isLoading && currentItems.map((loan) => (
                                <tr
                                    key={loan.id}
                                    className={loan.dateReturned.length > 0 ? 'table-success' : 'table-danger'}
                                >
                                    {/*<td>{book.id}</td>*/}
                                    {!bookId && <td>{loan.book.title}</td>}
                                    {!readerId && <td>{loan.reader.name}</td>}
                                    <td>{loan.dateBorrowed}</td>
                                    <td>{loan.dateReturned}</td>
                                    <td>{loan.borrowedBy}</td>
                                    <td>
                                        <span
                                            className="btn-link text-danger"
                                            title="Delete Book"
                                            onClick={() => handleDelete(loan)}
                                        >
                                            <FontAwesomeIcon icon="fa-trash"/>
                                        </span>
                                        <BorrowBook
                                            loanId={loan.id}
                                            bookId={bookId ?? null}
                                            readerId={readerId ?? null}
                                        />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </>
                </div>
            </div>
            <Paginator
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
            />
        </div>
    )
}

export default BookBorrowers;
