import React from 'react';
import SortHeader from '../components/SortHeader';
import SearchInput from '../components/SearchInput';
import {search} from '../components/SearchAndSort';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {updateFieldById} from '../components/addUpdateRecord';

const BooksGridView = (props) => {
    
    const {
        user,
        isLoading,
        sortOrder,
        currentSortKey,
        handleSortClick,
        handleSearch,
        currentItems,
        handleDelete,
        searchObj,
        resetSearch,
        setTableData
    } = props;
    return (
        <>
            
            <div className="row">
                <div className="col">
                    {!isLoading && <>
                        <table className="table table-striped border shadow">
                            <thead>
                            
                            <tr>
                                <SortHeader
                                    label="Назва Книги"
                                    sortKey="title"
                                    sortOrder={sortOrder}
                                    currentSortKey={currentSortKey}
                                    handleSortClick={handleSortClick}
                                />
                                <SortHeader
                                    label="Автор"
                                    sortKey="author"
                                    sortOrder={sortOrder}
                                    currentSortKey={currentSortKey}
                                    handleSortClick={handleSortClick}
                                />
                                <SortHeader
                                    label="Жанр"
                                    sortKey="genre"
                                    sortOrder={sortOrder}
                                    currentSortKey={currentSortKey}
                                    handleSortClick={handleSortClick}
                                />
                                <SortHeader
                                    label="Вікова Категорія"
                                    sortKey="auditory"
                                    sortOrder={sortOrder}
                                    currentSortKey={currentSortKey}
                                    handleSortClick={handleSortClick}
                                />
                                <SortHeader
                                    label="Доступна"
                                    sortKey="isAvailable"
                                    sortOrder={sortOrder}
                                    currentSortKey={currentSortKey}
                                    handleSortClick={handleSortClick}
                                />
                                {user && <th>Дії</th>}
                            </tr>
                            <tr>
                                <SearchInput
                                    fieldName="title"
                                    value={searchObj.title}
                                    onSearchChange={handleSearch}
                                />
                                <SearchInput
                                    fieldName="author"
                                    value={searchObj.author}
                                    onSearchChange={handleSearch}
                                />
                                <SearchInput
                                    fieldName="genre"
                                    value={searchObj.genre}
                                    onSearchChange={handleSearch}
                                />
                                <SearchInput
                                    type="select"
                                    choices={[
                                        {value: 'Дорослі', label: 'Дорослі'},
                                        {value: 'Підлітки', label: 'Підлітки'},
                                        {value: 'Діти', label: 'Діти'}
                                    ]}
                                    fieldName="auditory"
                                    value={searchObj.auditory}
                                    onSearchChange={handleSearch}
                                />
                                <SearchInput
                                    type="select"
                                    choices={[
                                        {value: true, label: 'ТАК'},
                                        {value: false, label: 'НІ'}
                                    ]}
                                    fieldName="isAvailable"
                                    value={searchObj.isAvailable}
                                    onSearchChange={handleSearch}
                                />
                                {user && <th className="pe-0 pb-2">
                                    <span
                                        className="btn-link text-warning ms-3"
                                        title="Скинути Фільтри"
                                        onClick={() => resetSearch()}
                                    >
                                        <FontAwesomeIcon icon="fa-filter" size="2x"/>
                                    </span>
                                </th>}
                            
                            </tr>
                            </thead>
                            <tbody>
                            {currentItems.map((book) => (
                                <tr key={book.id} className="hidden sd:table-row">
                                    <td className="text-truncate" style={{maxWidth: "150px"}}>{book.title}</td>
                                    <td className="text-truncate"
                                        style={{maxWidth: "100px"}}>{book.author}</td>
                                    <td className="p-sm-1">{book.genre}</td>
                                    <td className="p-sm-1">{book.auditory}</td>
                                    <td>
                                        <div className="form-check form-switch me-2">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="is-available"
                                                checked={book.isAvailable}
                                                onChange={async (e) => {
                                                    const newValue = e.target.checked;
                                                    try {
                                                        await updateFieldById('books', book.id, 'isAvailable', newValue);
                                                        setTableData((prevData) =>
                                                            prevData.map((item) =>
                                                                item.id === book.id ? {
                                                                    ...item,
                                                                    isAvailable: newValue
                                                                } : item
                                                            )
                                                        );
                                                    } catch (error) {
                                                        console.error("Error updating document: ", error);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </td>
                                    {user && (
                                        <td className="text-nowrap">
                                        <span
                                            className="btn-link text-danger me-2"
                                            title="Delete Book"
                                            onClick={() => handleDelete(book.id)}
                                        >
                                            <FontAwesomeIcon icon="fa-trash"/>
                                        </span>
                                            <a
                                                className="btn-link text-warning me-2"
                                                title="Редагувати Книгу"
                                                href={`/books/edit-book/${book.id}`}
                                            >
                                                <FontAwesomeIcon icon="fa-edit"/>
                                            </a>
                                            <a
                                                className="btn-link text-primary"
                                                title="Читачі Книги"
                                                href={`/books/book-borrowers/${book.id}`}
                                            >
                                                <FontAwesomeIcon icon="fa-list"/>
                                            </a>
                                        </td>
                                    )}
                                </tr>
                            
                            ))}
                            </tbody>
                        </table>
                    </>}
                </div>
            </div>
        </>
    )
}
export default BooksGridView;
