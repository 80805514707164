import React from 'react';

const SearchInput = React.memo((props) => {
    const {fieldName, value, onSearchChange, type = 'text', choices = []} = props;
    
    switch (type) {
        case 'text':
            return (
                <th>
                    <input
                        type='text'
                        id={`search-${fieldName}`}
                        className="form-control search-input"
                        placeholder="Пошук..."
                        value={value}
                        onChange={(event) => onSearchChange(event.target.value, fieldName)}
                    />
                </th>
            );
        case 'select':
            return (
                <th>
                    <select
                        id={`search-${fieldName}`}
                        className="form-control search-input"
                        onChange={(event) => onSearchChange(event.target.value, fieldName)}
                        value={value}
                    >
                        <option value="">Всі Записи</option>
                        {choices.map((choice, idx) => (
                            <option key={idx} value={choice.value}>{choice.label}</option>
                        ))}
                    </select>
                </th>
            );
    }
    
    
});

export default SearchInput;
