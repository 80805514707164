import React from 'react';

const RecordsInfo = ({currentPage, totalRecords, currentItems}) => {
    const startRecord = (currentPage - 1) * currentItems + 1;
    const endRecord = Math.min(currentPage * currentItems, totalRecords);
    
    return (
        <div className="records-info ms-3">
            Showing {startRecord} to {endRecord} of {totalRecords} records
        </div>
    );
};

export default RecordsInfo;
