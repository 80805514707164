import React, {useEffect, useState} from 'react';
import BookTile from './BookTile';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const RenderSearch = ({searchValue, setSearchValue}) => {
    return (
        <input
            type="text"
            value={searchValue}
            className="form-control me-2"
            placeholder="Пошук..."
            onChange={(e) => setSearchValue(e.target.value)}
        />
    );
    
}

const BooksTilesView = (props) => {
    const {
        handleSearch,
        currentItems,
        searchValue,
        setSearchValue
    } = props;
    
    return (
        <div className="container-fluid">
            <div className="border border-secondary shadow rounded bg-light mt-2 p-3 mb-3">
                <div className="row">
                    <div className="col-12 col-lg-2 d-flex align-items-center mb-2 mb-lg-0" id="search-col-1">
                        <RenderSearch searchValue={searchValue} setSearchValue={setSearchValue}/>
                    
                    </div>
                    <div
                        className="col-12 col-lg-10 mt-1 d-flex flex-wrap align-items-center justify-content-end ps-1 pe-1">
                        <div className="form-check form-switch me-2">
                            <input
                                className="form-check-input me-1"
                                type="checkbox"
                                id="filter-adults"
                                onChange={(e) => {
                                    handleSearch(e.target.checked ? 'Діти' : '', 'auditory');
                                    document.getElementById('filter-children').checked = false;
                                    document.getElementById('filter-teenagers').checked = false;
                                }}
                            />
                            <label className="form-check-label" htmlFor="filter-adults">
                                Діти
                            </label>
                        </div>
                        <div className="form-check form-switch me-2">
                            <input
                                className="form-check-input me-1"
                                type="checkbox"
                                id="filter-children"
                                onChange={(e) => {
                                    handleSearch(e.target.checked ? 'Дорослі' : '', 'auditory');
                                    document.getElementById('filter-adults').checked = false;
                                    document.getElementById('filter-teenagers').checked = false;
                                }}
                            />
                            <label className="form-check-label" htmlFor="filter-children">
                                Дорослі
                            </label>
                        </div>
                        <div className="form-check form-switch me-2">
                            <input
                                className="form-check-input me-1"
                                type="checkbox"
                                id="filter-teenagers"
                                onChange={(e) => {
                                    handleSearch(e.target.checked ? 'Підлітки' : '', 'auditory');
                                    document.getElementById('filter-adults').checked = false;
                                    document.getElementById('filter-children').checked = false;
                                }}
                            />
                            <label className="form-check-label" htmlFor="filter-teenagers">
                                Підлітки
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row">
                {currentItems.map((book, index) => (
                    <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                        <BookTile book={book}/>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default BooksTilesView;
