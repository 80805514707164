import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


const SortHeader = (props) => {
    const {label, sortKey, sortOrder, currentSortKey, handleSortClick} = props;
    let arrow = <FontAwesomeIcon icon="fa-sort-up" className="ms-1"/>;
    if (sortOrder === 'desc') {
        arrow = <FontAwesomeIcon icon="fa-sort-down" className="ms-1"/>;
    }
    
    return (
        <th className="table-header">
                <span
                    className="btn-link"
                    title="Клацніть для Сортування"
                    onClick={() => handleSortClick(sortKey)}
                >
                    {label} {currentSortKey === sortKey && arrow}
                </span>
        </th>
    
    );
};

export default SortHeader;
