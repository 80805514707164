import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {auth, googleProvider, signInWithPopup, signOut} from '../firebase';
import {Breadcrumb} from 'react-bootstrap';
import {AuthContext} from '../components/AuthProvider';


const Login = () => {
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const usr = result.user;
            const email = usr.email;
            const domain = email.substring(email.lastIndexOf('@') + 1);
            
            if (domain === 'leeds-uca.co.uk') {
                console.log('User is part of the organization:', usr);
                navigate('/'); // Redirect to home page
            } else {
                console.error('User is not part of the organization');
                await signOut(auth);
                alert('You are not authorized to access this application.');
            }
        } catch (error) {
            console.error('Error signing in with Google:', error);
        }
    };
    
    const signOutUser = async () => {
        try {
            await signOut(auth);
            console.log('User signed out');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };
    
    return (
        <div>
            <div className="row">
                <div className="col ps-4">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Домашня Сторінка</Breadcrumb.Item>
                        <Breadcrumb.Item active>Увійти</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="row">
                <div className="row">
                    <div className="col text-center pt-5">
                        <h4>Будь ласка увійдіть зі своїм Leeds Ukrainian Community Association обліковим записом</h4>
                        {!user ? (
                            <button className="btn btn-outline-primary fw-bold" onClick={signInWithGoogle}>
                                Увійти
                            </button>
                        ) : (
                            <button className="btn btn-outline-secondary fw-bold" onClick={signOutUser}>
                                Вийти
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
