import React, {useEffect} from 'react';
import {ChevronLeft, ChevronRight} from 'lucide-react';

const Paginator = ({currentPage, setCurrentPage, totalPages}) => {
    // Generate array of page numbers to display
    const getPageNumbers = () => {
        const pages = [];
        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(totalPages, startPage + 4);
        
        // Adjust startPage if we're near the end
        if (endPage - startPage < 4) {
            startPage = Math.max(1, endPage - 4);
        }
        
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };
    
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
        }
    };
    
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage])
    
    const pageNumbers = getPageNumbers();
    
    return (
        <div className="d-flex align-items-center justify-content-center my-3">
            {/* Previous button */}
            <button
                className="btn btn-sm btn-primary me-2"
                onClick={prevPage}
                disabled={currentPage === 1}
                aria-label="Previous page"
            >
                <ChevronLeft size={20}/>
            </button>
            
            {/* First page if not in view */}
            {pageNumbers[0] > 1 && (
                <>
                    <button
                        className={`btn btn-sm ${
                            currentPage === 1 ? 'btn-primary' : 'btn-outline-primary'
                        } me-2`}
                        onClick={() => setCurrentPage(1)}
                    >
                        1
                    </button>
                    {pageNumbers[0] > 2 && (
                        <span className="me-2">...</span>
                    )}
                </>
            )}
            
            {/* Numbered page buttons */}
            {pageNumbers.map(number => (
                <button
                    key={number}
                    className={`btn btn-sm ${
                        currentPage === number ? 'btn-primary' : 'btn-outline-primary'
                    } me-2`}
                    onClick={() => setCurrentPage(number)}
                >
                    {number}
                </button>
            ))}
            
            {/* Last page if not in view */}
            {pageNumbers[pageNumbers.length - 1] < totalPages && (
                <>
                    {pageNumbers[pageNumbers.length - 1] < totalPages - 1 && (
                        <span className="me-2">...</span>
                    )}
                    <button
                        className={`btn btn-sm ${
                            currentPage === totalPages ? 'btn-primary' : 'btn-outline-primary'
                        } me-2`}
                        onClick={() => setCurrentPage(totalPages)}
                    >
                        {totalPages}
                    </button>
                </>
            )}
            
            {/* Next button */}
            <button
                className="btn btn-sm btn-primary"
                onClick={nextPage}
                disabled={currentPage === totalPages}
                aria-label="Next page"
            >
                <ChevronRight size={20}/>
            </button>
        </div>
    );
};

export default Paginator;
