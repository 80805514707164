import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {getData, submitData} from '../components/addUpdateRecord';
import {Breadcrumb} from 'react-bootstrap';


const AddBook = () => {
    const {readerId} = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        phoneNumberAlt: ''
    });
    
    const handleSubmit = async () => {
        
        setIsSubmitting(true)
        submitData(formData, 'readers', readerId).then(() => {
            document.location.href = '/readers';
        })
    }
    
    useEffect(() => {
        if (readerId) {
            getData(readerId, 'readers').then((res) => {
                setFormData(res);
            });
        }
    }, [])
    
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Домашня Сторінка</Breadcrumb.Item>
                        <Breadcrumb.Item href="/readers">Читачі</Breadcrumb.Item>
                        <Breadcrumb.Item active>Дотати/Редагувати Читача</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-sm">
                    <div className="  border border-secondary shadow rounded bg-light mt-3 p-3">
                        <div className="row mb-2">
                            <div className="col">
                                <h3>Редагування Читача</h3>
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <label
                                        htmlFor="name"
                                        className="form-label label-required"
                                    >Ім'я та Прізвище</label>
                                    <input
                                        id="name"
                                        type="text"
                                        required={true}
                                        className="form-control"
                                        value={formData.name}
                                        onChange={(event) => setFormData((prevState) => ({
                                            ...prevState,
                                            name: event.target.value,
                                        }))}
                                    />
                                </div>
                            
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <label
                                        htmlFor="email"
                                        className="form-label label-required"
                                    >Електронна Адреса</label>
                                    <input
                                        id="email"
                                        type="email"
                                        required={true}
                                        className="form-control"
                                        value={formData.email}
                                        onChange={(event) => setFormData((prevState) => ({
                                            ...prevState,
                                            email: event.target.value,
                                        }))}
                                    />
                                </div>
                                <div className="col">
                                    <label
                                        htmlFor="phone"
                                        className="form-label label-required"
                                    >Номер Телефону</label>
                                    <input
                                        id="phone"
                                        type="text"
                                        required={true}
                                        className="form-control"
                                        value={formData.phoneNumber}
                                        onChange={(event) => setFormData((prevState) => ({
                                            ...prevState,
                                            phoneNumber: event.target.value,
                                        }))}
                                    />
                                </div>
                                <div className="col">
                                    <label
                                        htmlFor="phone-alt"
                                        className="form-label label-required"
                                    >Альтернативний Номер Телефону</label>
                                    <input
                                        id="phone-alt"
                                        type="text"
                                        required={true}
                                        className="form-control"
                                        value={formData.phoneNumberAlt}
                                        onChange={(event) => setFormData((prevState) => ({
                                            ...prevState,
                                            phoneNumberAlt: event.target.value,
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <button className="btn btn-sm btn-outline-primary"
                                            onClick={() => handleSubmit().then(() => setIsSubmitting(false))}
                                    >
                                        {isSubmitting
                                            ?
                                            <span><i className="fas fa-spinner fa-spin me-2"/>Відправка Даних...</span>
                                            : <strong>Зберегти</strong>
                                        }
                                    
                                    </button>
                                    <a className="btn btn-sm btn-outline-dark ms-2"
                                       href="/readers"
                                    >Відміна
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddBook
