import * as React from "react";
import Home from "./pages/Home";
import Books from "./pages/Books";
import AddBook from './pages/AddBook';
import Readers from "./pages/Readers";
import BookBorrowers from './pages/BookBorrowers';
import Login from './pages/Login';
import AddReader from './pages/AddReader';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from "react-router-dom";

let router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<Home/>}>
                <Route path="books" element={<Books/>}/>
                <Route path="books">
                    <Route path="edit-book/:bookId?" element={<AddBook/>}/>
                    <Route path="book-borrowers/:bookId?" element={<BookBorrowers/>}/>
                </Route>
                <Route path="readers" element={<Readers/>}/>
                <Route path="readers">
                    <Route path="edit-reader/:readerId?" element={<AddReader/>}/>
                    <Route path="reader-books/:readerId?" element={<BookBorrowers/>}/>
                
                </Route>
                <Route path="loans" element={<BookBorrowers/>}/>
                <Route path="login" element={<Login/>}/>
            </Route>
        </>
    )
);

if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
}

export default function App() {
    return <RouterProvider router={router}/>;
}
