import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Breadcrumb} from 'react-bootstrap';
import {getData, submitData} from '../components/addUpdateRecord';
import {getDownloadURL, ref, uploadBytes, deleteObject} from 'firebase/storage';
import {storage} from '../firebase';
import CryptoJS from 'crypto-js';

const AddBook = () => {
    const {bookId} = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        author: '',
        genre: '',
        publisher: '',
        description: '',
        isAvailable: true,
        imageUrl: '',
        auditory: ''
    });
    const leftColumnRef = useRef(null);
    
    const uploadImage = async (file) => {
        try {
            let imageRef;
            if (formData.title.length > 0) {
                const imageName = CryptoJS.MD5(formData.title).toString();
                const previousImageRef = ref(storage, `images/${imageName}`);
                await deleteObject(previousImageRef).catch((error) => {
                    console.warn(`Error deleting previous image for book ${imageName}: `, error);
                });
                imageRef = ref(storage, `images/${imageName}`);
                
                await uploadBytes(imageRef, file);
                return await getDownloadURL(imageRef);
            }
            return '';
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    };
    
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            uploadImage(file).then((url) => {
                setFormData({
                    ...formData,
                    imageUrl: url,
                });
            })
            
        }
        
    }
    const handleSubmit = async () => {
        setIsSubmitting(true)
        submitData(formData, 'books', bookId).then(() => {
            document.location.href = '/books';
        })
    }
    
    useEffect(() => {
        if (bookId) {
            getData(bookId, 'books').then((res) => {
                setFormData(res);
            });
        }
    }, [])
    
    
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Домашня Сторінка</Breadcrumb.Item>
                        <Breadcrumb.Item href="/books">Книги</Breadcrumb.Item>
                        <Breadcrumb.Item active>Додати/Редагувати Книгу</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-sm ">
                    <div className="  border border-secondary shadow rounded bg-light mt-3 p-3" ref={leftColumnRef}>
                        <div className="row mb-2">
                            <div className="col">
                                <h3>Редагування Книги</h3>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <div className="border border-info rounded p-2">
                                    <div className="form-check form-switch d-inline-block">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={formData.isAvailable}
                                            id="is-available"
                                            onChange={(e) => setFormData({
                                                ...formData,
                                                isAvailable: e.target.checked,
                                            })}
                                        />
                                        <label className="form-check-label" htmlFor="is-available">
                                            Книга Доступна для Видачі
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label
                                    htmlFor="title"
                                    className="form-label label-required"
                                >Назва Книги</label>
                                <input
                                    id="title"
                                    type="text"
                                    required={true}
                                    className="form-control"
                                    value={formData.title}
                                    onChange={(event) => setFormData((prevState) => ({
                                        ...prevState,
                                        title: event.target.value,
                                    }))}
                                />
                            </div>
                            <div className="col">
                                <label
                                    htmlFor="author"
                                    className="form-label label-required"
                                >Автор</label>
                                <input
                                    id="author"
                                    type="text"
                                    required={true}
                                    className="form-control"
                                    value={formData.author}
                                    onChange={(event) => setFormData((prevState) => ({
                                        ...prevState,
                                        author: event.target.value,
                                    }))}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label
                                    htmlFor="genre"
                                    className="form-label"
                                >Жанр</label>
                                <input
                                    id="genre"
                                    type="text"
                                    className="form-control"
                                    value={formData.genre}
                                    onChange={(event) => setFormData((prevState) => ({
                                        ...prevState,
                                        genre: event.target.value,
                                    }))}
                                />
                            </div>
                            <div className="col">
                                <label
                                    htmlFor="publisher"
                                    className="form-label"
                                >Видавництво</label>
                                <input
                                    id="publisher"
                                    type="text"
                                    className="form-control"
                                    value={formData.publisher}
                                    onChange={(event) => setFormData((prevState) => ({
                                        ...prevState,
                                        publisher: event.target.value,
                                    }))}
                                />
                            </div>
                            <div className="col">
                                <label
                                    htmlFor="auditory"
                                    className="form-label"
                                >Вікова Категорія</label>
                                <select
                                    id="auditory"
                                    className="form-control"
                                    value={formData.auditory}
                                    onChange={(event) => setFormData((prevState) => ({
                                        ...prevState,
                                        auditory: event.target.value,
                                    }))}
                                >
                                    <option value="">Select Value</option>
                                    <option value="Дорослі">Дорослі</option>
                                    <option value="Підлітки">Підлітки</option>
                                    <option value="Діти">Діти</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col">
                                <label
                                    htmlFor="description"
                                    className="form-label"
                                >Короткий Опис</label>
                                <textarea
                                    id="description"
                                    className="form-control"
                                    cols="30"
                                    rows="6"
                                    value={formData.description}
                                    onChange={(event) => setFormData((prevState) => ({
                                        ...prevState,
                                        description: event.target.value,
                                    }))}
                                />
                            </div>
                        </div>
                        {formData.title.length > 0
                            && <div className="row mb-3">
                                <div className="col">
                                    <div className=" border border-secondary rounded bg-light mt-3 p-3">
                                        <input type="file" onChange={handleFileChange}/>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="row mb-3">
                            <div className="col">
                                <button className="btn btn-sm btn-outline-primary"
                                        onClick={() => handleSubmit().then(() => setIsSubmitting(false))}
                                >
                                    {isSubmitting
                                        ? <span><i className="fas fa-spinner fa-spin me-2"/>Відправка Даних...</span>
                                        : <strong>Зберегти</strong>
                                    }
                                
                                </button>
                                <a className="btn btn-sm btn-outline-dark ms-2"
                                   href="/books"
                                >Відміна
                                </a>
                            </div>
                        </div>
                    </div>
                
                </div>
            
            </div>
        
        </div>
    )
}

export default AddBook
