import React from 'react';
import {Card} from 'react-bootstrap'; // Ensure you have react-bootstrap installed

const BookTile = ({book}) => {
    return (
        <Card className="h-100 shadow d-flex flex-column bg-light">
            <Card.Img
                className="w-100 p-2"
                style={{height: '300px', objectFit: 'contain', objectPosition: 'center'}}
                variant="top"
                src={book.imageUrl || 'placeholder.jpg'}
                alt={book.title}
            />
            
            <Card.Body className="d-flex flex-column">
                <hr className="my-2"/>
                <Card.Title className="mb-2"><strong>{book.title}</strong></Card.Title>
                <hr className="my-2"/>
                <div className="mb-1 small">
                    <strong>Доступна:</strong>
                    {book.isAvailable
                        ? <span className="badge bg-success ms-2">ТАК</span>
                        : <span className="badge bg-danger ms-2">НІ</span>
                    }
                </div>
                <div className="mb-1 small">
                    <strong>Автор:</strong> {book.author}
                </div>
                <div className="mb-1 small">
                    <strong>Жанр:</strong> {book.genre}
                </div>
                <div className="mb-1 small">
                    <strong>Вікова Категорія:</strong> {book.auditory}
                </div>
                <div className="mb-1 small">
                    <strong>Видавництво:</strong> {book.publisher}
                </div>
                <hr className="my-2"/>
                
                <div className="mt-1">
                    <strong>Короткий Опис:</strong>
                    <div className="alert alert-light p-2 mt-1 book-description"
                         style={{maxHeight: '200px', overflowY: 'auto'}}>
                        {book.description}
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default BookTile;
