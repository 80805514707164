import {db} from '../firebase';
import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where,} from 'firebase/firestore';

const submitData = async (formData, collectionName, id = null) => {
    
    if (id) {
        try {
            const docRef = doc(
                db,
                collectionName,
                id
            );
            return await updateDoc(docRef, formData);
            
        } catch (error) {
            console.error("Error updating document: ", error);
            throw error;
        }
    } else {
        try {
            return await addDoc(
                collection(db, collectionName),
                formData
            );
        } catch (error) {
            console.error("Error adding document: ", error);
            throw error;
        }
    }
}

const updateFieldById = async (collectionName, id, fieldName, fieldValue) => {
    try {
        const docRef = doc(db, collectionName, id);
        return await updateDoc(docRef, {[fieldName]: fieldValue});
    } catch (error) {
        console.error("Error updating field: ", error);
        throw error;
    }
}


const getData = async (id, collectionName) => {
    try {
        const docRef = doc(db, collectionName, id);
        const docSnap = await getDoc(docRef);
        console.log('get data func...');
        if (docSnap.exists()) {
            return {id: docSnap.id, ...docSnap.data()};
        } else {
            console.log("No such document!");
            return null;
        }
    } catch (error) {
        console.error("Error getting document:", error);
        throw error;
    }
};

const getDataByAnyField = async (value, fieldName, collectionName) => {
    try {
        console.log('get data by field func...');
        const q = query(collection(
                db, collectionName),
            where(fieldName, "==", value)
        );
        const dataSnapshot = await getDocs(q);
        return dataSnapshot.docs.map((doc) => {
            return {id: doc.id, ...doc.data()}
        });
    } catch (error) {
        console.error("Error getting document:", error);
        return [];
    }
    
};

const getAllCollectionRecords = async (collectionName) => {
    const collectionRef = collection(db, collectionName);
    const q = query(collectionRef);
    
    try {
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    } catch (error) {
        console.error("Error getting documents: ", error);
        throw error;
    }
    
}

const deleteDocumentById = async (collectionName, docId) => {
    try {
        const docRef = doc(db, collectionName, docId);
        await deleteDoc(docRef);
        console.log("Document successfully deleted");
    } catch (error) {
        console.error("Error deleting document: ", error);
        throw error;
    }
};

export {
    submitData,
    getData,
    deleteDocumentById,
    getAllCollectionRecords,
    getDataByAnyField,
    updateFieldById
};
